<template>
  <div class="app-container">
    <h1>Deeplink test link</h1>
    <p><a :href="this.androidLink">Android Link <u>({{ this.androidLink }})</u></a></p>
    <p><a :href="this.iosLink">iOS Link <u>({{ this.iosLink }})</u></a></p>
  </div>
</template>

<script>
import { Message } from "element-ui";
import { getOSToken } from '@/utils/auth'
import defaultSettings from '@/settings'

export default {
  name: "deeplink_test",
  components: {},
  data() {
    return {
      androidLink: "",
      iosLink: "",
    };
  },
  methods: {
    IsIOS() {
      let OS = getOSToken();
      return OS && OS.toLowerCase() === 'ios';
    },
  },
  created() {
    let serverType = defaultSettings.server.toUpperCase();
    //serverType :
    //LOCAL,
    //DEV,
    //BETA,
    //LIVE
    this.androidLink = `zhdl://zombiehuntervng.com?openbpevent`;
    this.iosLink = `zhdl://vng.zombiehunter?openbpevent`;
  },
}
</script>
